@import "tailwindcss/base";

html {
  font-family: "Inter", sans-serif;
}

@import "tailwindcss/components";
@import "tailwindcss/utilities";

body.ReactModal__Body--open {
  height: 100vh;
  overflow-y: hidden;
}

.ReactModal__Content--after-open:focus {
  outline: 0;
}
